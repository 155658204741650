.number-indicator {
    font-size: 48px;
    font-weight: 700;
    color: #4F8B62;
    font-family: 'Arial', sans-serif;
    text-align: center;
    margin: 40px 0;

    &.outline {
        font-size: 50px;
        color: transparent; 
        -webkit-text-stroke: 1px #4F8B62; 
        font-weight: 500;
    }
}
