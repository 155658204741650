.research-page {
    display: flex;
    justify-content: center;
    gap: 30px;

    .page-content {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 767px) {
            width: auto;
            gap: 30px;
        }

        p {
            margin: 0px;
        }

        .title {
            color: #404A3D;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 46px;
        }

        .research-topic-container {
            display: flex;
            flex-direction: row;
            gap: 100px;

            @media (max-width: 767px) {
                flex-direction: column;
                gap: 30px;
              }

            .subtopic-container {
                display: flex;
                flex-direction: column;
                gap: 30px;



                .description {
                    color: rgba(102, 102, 102, 1);

                    &.bolded {
                        font-weight: 600;
                    }
                }



                .research-section {
                    margin-top: 60px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    @media (max-width: 767px) {
                        margin-top: 10px;
                      }

                    .research-item {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        svg {
                            min-width: 20px;
                            height: 20px;
                        }

                        span {
                            line-height: 26px;
                            color: rgba(64, 74, 61, 1);
                            font-weight: 500;
                        }
                    }
                }

                .infrastructure-section {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .infrastructure-item {
                        display: flex;
                        align-items: center;
                        gap: 10px;


                        span {
                            line-height: 26px;
                            color: rgba(64, 74, 61, 1);
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        .reports-and-results-container {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .reports-and-results {
                display: flex;
                gap: 40px;

                @media (max-width: 767px) {
                    flex-direction: column;
                  }

                .subtitle {
                    height: 46px;
                    border-radius: 20px;
                    background: rgba(91, 140, 81, 1);
                    display: flex;
                    align-items: center;
                    padding: 10px 40px 15px 20px;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 30px;
                    color: rgba(255, 255, 255, 1);

                    @media (max-width: 767px) {
                        height: auto;
                        font-size: 16px;
                      }
                }

                .subtopic {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 45px;

                    @media (max-width: 767px) {
                        width: 100%;
                      }

                    .category {
                        display: flex;
                        flex-direction: column;

                        .title {
                            font-size: 16px;
                            color: rgba(102, 102, 102, 1);
                            font-weight: 600;
                        }

                        .results {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 20px;
                        }
                    }
                }
            }
        }


        .performances-container {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .performances-title {
                color: rgba(64, 74, 61, 1);
                font-size: 26px;
                font-weight: 500;
            }

            .sections-container {
                display: flex;
                width: 100%;
                justify-content: space-between;

                @media (max-width: 767px) {
                    flex-direction: column;
                    gap: 40px;
                  }
            }

            .performances-section {
                display: flex;
                flex-direction: column;
                gap: 10px;

                &.section-2 {
                    width: 45%;
                    @media (max-width: 767px) {
                        width: 100%;
                      }
                }

                .item {
                    color: rgba(102, 102, 102, 1);
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .dot {
                        min-width: 4px;
                        min-height: 4px;
                        border-radius: 50%;
                        background-color: rgba(64, 74, 61, 1);
                    }
                }
            }
        }


        .resources-panel-container {
            display: flex;
            gap: 80px;

            @media (max-width: 767px) {
                flex-direction: column;
              }

            .panel {
                width: 30%;
                display: flex;
                flex-direction: column;
                gap: 20px;

                @media (max-width: 767px) {
                    width: 100%;
                  }

                .title {
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 32px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;

                }

                .section {
                    margin-top: 10px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }

            .separator {
                height: 5px;
                background-color: rgba(217, 217, 199, 1)
            }
        }
    }


    .separator {
        height: 1px;
        width: 100%;
        background-color: rgba(217, 217, 199, 1)
    }

    .hidden {
        visibility: hidden;
        @media (max-width: 767px) {
            display: none;
          }
    }

    .dot {
        min-width: 5px;
        min-height: 5px;
        border-radius: 50%;
        background-color: rgba(64, 74, 61, 1);
        ;
    }
}