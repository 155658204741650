.events-page {
    .page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 40px;
        gap: 20px;


        .events-container {
            width: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: 767px) {
                width: 100%;
            }

            .item {
                width: 30%;
                border-radius: 26px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                cursor: pointer;
                opacity: 0;
                transform: translateY(20px);
                animation: fadeIn 0.8s ease forwards;

                @media (max-width: 767px) {
                    width: 100%;

                }

                &:hover {
                    transform: translateY(-10px) scale(1.02);
                    transition: transform 0.3s ease, box-shadow 0.3s ease;
                }

                .image-container {
                    position: relative;
                    box-sizing: border-box;
                    width: 100%;
                    transition: transform 0.3s ease;

                    &:hover {
                        transform: scale(1.05);
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 26px;
                    }

                    .category {
                        top: 20px;
                        left: 20px;
                        position: absolute;
                        background-color: white;
                        padding: 0px 20px;
                        border-radius: 30px;
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        transition: transform 0.3s ease, opacity 0.3s ease;
                        opacity: 0;
                        transform: translateY(-10px);

                        span {
                            color: #404A3D;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 24px;
                            letter-spacing: 0.5px;
                            text-transform: capitalize;
                        }
                    }

                    &:hover .category {
                        opacity: 1;
                        transform: translateY(0);
                    }

                    .arrow-container {
                        bottom: 5px;
                        right: 0px;
                        position: absolute;
                        display: flex;
                        width: 50px;
                        height: 50px;
                        border-radius: 50px;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;
                        background-color: #EDDD5E;
                        transition: transform 0.3s ease;

                        &:hover {
                            transform: scale(1.1) rotate(15deg);
                        }
                    }
                }

                .title {
                    color: #404A3D;
                    font-size: 23px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 38px;
                    transition: color 0.3s ease;

                    @media (max-width: 767px) {
                        color: #404A3D;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 27.728px;
                    }

                    &:hover {
                        color: #5B8C51;
                    }
                }

                .description {
                    width: 80%;
                    height: 80px;
                    color: rgba(64, 74, 61, 0.80);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25.6px;
                    transition: color 0.3s ease;

                    &:hover {
                        color: rgba(64, 74, 61, 1);
                    }
                }

                /* Keyframe for render animation */
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                        transform: translateY(20px);
                    }

                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
    }
}