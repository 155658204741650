.home-page {
    background-color: #FFFDEA;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;

    @media (max-width: 767px) {
        gap: 40px;
    }


    .scrolling-text {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        color: #404A3D;
        font-size: 26px;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding: 20px 0;
        position: relative;
        color: rgb(71, 140, 65);
        text-shadow: 1px 1px 0px rgba(64, 74, 61, 0.50), -1px -1px 0px rgba(64, 74, 61, 0.50), 1px -1px 0px rgba(64, 74, 61, 0.50), -1px 1px 0px rgba(64, 74, 61, 0.50), 1px 1px 0px rgba(64, 74, 61, 0.50);
        font-size: 100px;
        font-style: normal;
        font-weight: 700;
        line-height: 120px;

        @media (max-width: 767px) {
            color: rgb(71, 140, 65);
            text-shadow: 0.388px 0.388px 0px rgba(64, 74, 61, 0.50), -0.388px -0.388px 0px rgba(64, 74, 61, 0.50), 0.388px -0.388px 0px rgba(64, 74, 61, 0.50), -0.388px 0.388px 0px rgba(64, 74, 61, 0.50), 0.388px 0.988px 0px rgba(64, 74, 61, 0.50);
            font-size: 38.801px;
            font-style: normal;
            font-weight: 700;
            line-height: 46.562px;
        }

        span {
            display: inline-block;
            padding-left: 100%;
            animation: scroll 20s linear infinite;
        }

        .star-icon {
            margin: 0 20px;
        }
    }


    @keyframes scroll {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-100%);
        }
    }
}