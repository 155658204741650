.dropdown-menu {
    position: relative;
    cursor: pointer;
  
    .dropdown-label {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  
  
    .dropdown-content {
        width: 180px;
        position: absolute;
        top: 35px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(237, 221, 94, 1);
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        z-index: 10;
      
        &::before {
          content: '';
          position: absolute;
          top: -9px; 
          left: 50%;
          transform: translateX(-50%);
          border-width: 0 10px 10px 10px;
          border-style: solid;
          border-color: transparent transparent rgba(237, 221, 94, 1) transparent;
        }
      
        .dropdown-item {
          padding: 12px 15px;
          cursor: pointer;
          color: rgba(64, 74, 61, 1);
          border-bottom: 1px solid rgba(0, 0, 0, 1);
          font-weight: 500;
      
          &:hover {
            background-color: #f1c40f;
          }
      
          &:last-child {
            border-bottom: none;
          }
        }
      }
  }
  