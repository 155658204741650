.about-us-page {
    display: flex;
    justify-content: center;
    gap: 30px;

    @media (max-width: 767px) {

    }

    .side-menu-container {
        @media (max-width: 767px) {
            order: 2;
        }
    }

    .page-content {
        width: 900px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 767px) {
           width: auto;

           img {
            width: 100%;
            height: 100%;
           }
        }

        p {
            margin: 0px;
        }


        .topic-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .title {
                color: #404A3D;
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px;
            }

            .section-content {
                display: flex;
                flex-direction: column;

                color: #666;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25.6px;
                gap: 20px;


                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }

            .dropdown-title {
                padding: 0px 30px;
                display: flex;
                align-items: center;
                height: 65px;
                border-radius: 20px;
                background: #5B8C51;
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px;

                @media (max-width: 767px) {
                    height: auto;
                  }
                  
            }

            .subtitle-content {
                .subtitle {
                    color: #666;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25.6px;

                }

                li {
                    p {
                        color: #666;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25.6px;
                    }
                }
            }
        }
    }
}