.side-menu-container {
    width: 375px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 767px) {
        order: 2;
    }

    .about-baneasa-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .item {
            height: 70px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 5px 0px 30px;

            span {
                color: #404A3D;
                text-align: center;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                text-transform: uppercase;
            }

            &.title {
                justify-content: flex-start;
                gap: 10px;

                span {
                    color: #404A3D;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 34px;
                    text-transform: capitalize;
                }
            }
        }
    }
}