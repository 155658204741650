.homepage-laboratories-info-container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 767px) {
        width: 100%;
        flex-wrap: wrap;
    }

    .laboratory-container {
        position: relative;
        border-radius: 30px;
        width: 400px;
        /* // height: 280px; */
        background-color: #fff;
        padding: 35px 25px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 767px) {
            width: 100%;
        }

        .arrow-container {
            bottom: 0;
            right: 0;
            position: absolute;
            display: flex;
            width: 55px;
            height: 55px;
            border-radius: 50px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            background-color: #EDDD5E;
        }

        .title-section {
            width: 90%;
            display: flex;
            font-size: 22px;
            color: #404A3D;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            gap: 20px;

            @media (max-width: 767px) {
                color: #404A3D;
                font-size: 20.387px;
                font-style: normal;
                font-weight: 500;
                line-height: 27.801px;

            }



            svg {
                min-width: 80px;
            }


        }

        .separator {
            background-color: rgba(64, 74, 61, 0.3);
            width: 100%;
            height: 1px;
        }

        .descripton {
            width: 80%;
            color: rgba(64, 74, 61, 0.80);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 25.6px;

            @media (max-width: 767px) {
                color: rgba(64, 74, 61, 0.80);
                font-size: 14.827px;
                font-style: normal;
                font-weight: 400;
                line-height: 23.723px;
            }
        }
    }
}