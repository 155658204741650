.homepage-section-about-us {
    width: 90%;
    display: flex;
    gap: 100px;


    @media (max-width: 767px) {
        width: 100%;
        flex-direction: column;
        gap: 20px;
    }

    img {
        width: 50%;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .content {
        width: 50%;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;


        @media (max-width: 767px) {
            width: 100%;
            gap: 20px;
        }

        .title-help {
            width: 140px;
            height: 30px;
            background-color: white;
            padding: 0px 20px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            gap: 15px;

            span {
                color: #404A3D;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.5px;
                text-transform: capitalize;

            }
        }

        .title {
            color: #404A3D;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
        }

        .subtitle {
            color: #666;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25.6px;
        }

        .items-container {
            display: flex;
            align-items: flex-end;
            gap: 30px;

            @media (max-width: 767px) {
                flex-direction: column;
                gap: 50px;
            }

            .item {
                display: flex;
                flex-direction: column;
                gap: 40px;

                .title {
                    color: #404A3D;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 500;
                    white-space: nowrap;
                }

                .description {
                    height: 100px;
                    color: #666;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25.6px;


                    @media (max-width: 767px) {
                        height: auto;
                    }
                }
            }
        }
    }
}