.contact-form-container {
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 25px;
  
    .form-title {
        font-size: 35px;
        font-weight: 600;
        color: rgba(64, 74, 61, 1);
    }
  
    .form-description {
      font-size: 18px;
      color: #666;
      font-weight: 500;
      margin-bottom: 30px;
    }
  
    .contact-form {
      display: flex;
      flex-direction: column;
      gap: 15px;
      max-width: 600px;
  
      .form-row {
        display: flex;
        gap: 15px;
  
        input {
          width: 100%;
          padding: 15px 15px;
          border: none;
          border-radius: 8px;
          font-size: 14px;
          background: rgba(248, 247, 240, 1);
          color: rgba(108, 117, 125, 1);

          transition: all 0.3s ease;
  
          &:focus {
            border-color: none;
            outline: none;
            box-shadow: 0 0 5px rgba(131, 195, 65, 0.5);
          }
        }
      }
  
      textarea {
        width: 100%;
        padding: 12px 15px;
        border: none;
        border-radius: 8px;
        resize: none;
        background: rgba(248, 247, 240, 1);
        color: rgba(108, 117, 125, 1);
        font-size: 14px;
        transition: all 0.3s ease;
        font-family: sans-serif;
        &:focus {
          border-color: #83c341;
          outline: none;
          box-shadow: 0 0 5px rgba(131, 195, 65, 0.5);
        }
      }
  
      .submit-btn {
        height: 60px;
        width: 190px;
        margin-top: 15px;
        padding: 12px 25px;
        background: rgba(91, 140, 81, 1);
        color: #fff;
        border: none;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #6aa92e;
        }
  
     
      }
    }
  }
  