.app-footer {
    width: 100%;

    .categories-contact-info-container {
        height: 120px;
        background: #EDDD5E;
        display: flex;
        justify-content: space-around;
        align-items: center;


        @media (max-width: 767px) {
            padding-top: 20px;
            flex-direction: column;
            height: auto;
            padding-bottom: 30px;
            gap: 20px;
        }

        .categories {
            display: flex;
            gap: 20px;

            @media (max-width: 767px) {
                gap: 5px;
            }

            .category {
                display: flex;
                align-items: center;
                color: #404A3D;
                font-size: 13px;
                font-style: normal;
                line-height: 24px;
                letter-spacing: 0.5px;
                text-transform: uppercase;

                .dot {
                    margin-left: 20px;
                    width: 6px;
                    height: 6px;
                    border-radius: 3px;
                    background: #FFF;

                    @media (max-width: 767px) {
                        margin-left: 5px;
                    }
                }
            }


        }

        .contact-info {
            display: flex;
            gap: 20px;
            color: #404A3D;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;


            @media (max-width: 767px) {
                flex-direction: column;
            }

            .phone {
                display: flex;
                align-items: center;
                height: 50px;
                gap: 15px;


                .phone-icon {
                    height: 100%;
                    width: 50px;
                    background-color: #fff;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .email {
                display: flex;
                align-items: center;
                height: 50px;
                gap: 15px;

                .email-icon {
                    height: 100%;
                    width: 50px;
                    background-color: #fff;
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .details-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media (max-width: 767px) {
            flex-direction: column;
        }

        .logo-info {
            width: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;

            @media (max-width: 767px) {
                width: auto;
                align-items: center;
            }

            .info {
                color: #404A3D;
                font-size: 16px;
                font-style: normal;
                text-align: center;
                font-weight: 400;
                line-height: 26px
            }
        }

        .content {
            width: 50%;
            display: flex;
            flex-direction: column;
            padding: 40px 20px;
            color: #333;

            @media (max-width: 767px) {
                width: auto;
                align-items: center;
                padding: 0px;
            }

            .description-links-container {
                width: 85%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin-bottom: 20px;

                @media (max-width: 767px) {
                    width: 100%;
                    align-items: center;
                }

                .description {
                    text-align: start;
                    color: #404A3D;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 42px;

                    @media (max-width: 767px) {
                        width: 100%;
                        color: #404A3D;
                        text-align: center;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 36px;
                    }
                }

                .footer-columns {
                    display: flex;
                    align-items: start;
                    width: 100%;
                    gap: 20px;

                    @media (max-width: 767px) {
                        flex-direction: column;
                    }

                    .column {
                        flex: 1;
                        text-align: left;

                        &.address {
                            @media (max-width: 767px) {
                                width: 50%;
                            }
        
                        }

                        h4 {
                            font-size: 16px;
                            font-weight: bold;
                            margin-bottom: 10px;
                        }

                        ul {
                            list-style: none;
                            padding: 0;
                            margin: 0;

                            li {
                                font-size: 14px;
                                margin-bottom: 5px;
                            }
                        }

                        p {
                            font-size: 14px;
                        }
                    }
                }
            }

            .separator {
                height: 0.6px;
                width: 100%;
                background-color: rgba(64, 74, 61, 0.20);
                ;
            }

            .footer-bottom {
                display: flex;
                justify-content: space-between;
                color: #666;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22.4px;

                @media (max-width: 767px) {
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 20px;
                }

            }
        }
    }
}