.research-page-part-projects {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .projects-container {
        display: flex;
        justify-content: center;
        gap: 30px;


        @media (max-width: 767px) {
            flex-direction: column;
        }

        .item {
            width: 30%;
            max-width: 400px;
            height: 460px;
            position: relative;
            padding: 20px;
            border-radius: 26px;
            background: #FFF;
            display: flex;
            flex-direction: column;
            gap: 25px;
            opacity: 0;
            transform: translateY(50px);
            transition: opacity 0.6s ease-out, transform 0.6s ease-out, box-shadow 0.3s ease, transform 0.3s ease;
            cursor: pointer;

            @media (max-width: 767px) {
                width: 100%;
                height: 430px;
                max-width: fit-content;
            }

            &.visible {
                opacity: 1;
                transform: translateY(0);
            }

            &:hover {
                transform: scale(1.05);
                box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
            }

            .image-container {
                box-sizing: border-box;

                img {
                    width: 100%;
                    height: 301px;
                    border-radius: 26px;
                    object-fit: cover;
                }
            }

            .category {
                color: #999;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }

            .title {
                color: #404A3D;
                font-size: 26px;
                font-weight: 500;
                line-height: 32px;
                text-transform: capitalize;
            }

            .arrow-container {
                bottom: 0;
                right: 0;
                position: absolute;
                display: flex;
                width: 55px;
                height: 55px;
                border-radius: 50px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                background-color: #EDDD5E;
                transition: transform 0.3s ease;

                &:hover {
                    transform: rotate(45deg);
                }
            }
        }
    }
}
