.info-section-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;

    &::before {
        border-radius: 15px;
        position: absolute;
        width: 50px;
        height: 50px;
        top: -55px;
        right: 0px;
        content: '';
        box-shadow: 10px -10px 0 #FFFDEA;

    }


    &::after {
        border-radius: 15px;
        position: absolute;
        width: 50px;
        height: 30px;
        top: 0px;
        left: 0px;
        content: '';
        box-shadow: -30px -10px 0 #FFFDEA;

    }

    .section {
        display: flex;
        flex-direction: column;
        width: 70%;
        gap: 15px;

        .title-help-text {
            width: 180px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #fff;
            border-radius: 50px;
            color: #FFF;
            font-size: 12px;
        }

        .separator {
            width: 50%;
            height: 1px;
            background-color: #FFF;
        }

        .title {
            color: #FFF;
            font-family: Signika;
            font-size: 50px;
            font-style: normal;
            font-weight: 550;
            line-height: 84px;
        }

        .subtitle {
            color: #FFF;

        }

        .details-container {
            margin-top: 10px;
            width: 170px;
            height: 60px;
            background-color: #FFF;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    }
}