.app-header {
    box-sizing: border-box;
    padding: 20px 20px;
    padding-bottom: 0px;
    height: 500px;

    &.home {
        height: 700px;
    }

    .overlay {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background-size: cover!important; 
        background-position: center!important; 
        background-repeat: no-repeat!important;
        opacity: 5; 
        animation: fadeIn 1s ease-in forwards;


        @keyframes fadeIn {
            from {
              opacity: 0.5;
            }
            to {
              opacity: 1;
            }
          }

        .navbar-section {
            display: flex;
            flex-direction: row;

            .logo-container {
                position: relative;
                padding: 0 20px;
                background-color: #FFFDEA;
                border-radius: 0px 0px 30px 0px;

            }

            .navbar-container {
                padding: 0 40px;
                width: 100%;
                display: flex;
                position: relative;
                justify-content: flex-end;
                align-items: center;
                gap: 50px;
                height: 80px;
                &::before {
                    border-radius: 15px;
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    top: 0px;
                    right: 0px;
                    content: '';
                    box-shadow: 10px -10px 0 #FFFDEA;
                   
                }


                &::after {
                    border-radius: 15px;
                    position: absolute;
                    width: 50px;
                    height: 30px;
                    top: 0px;
                    left: 0px;
                    content: '';
                    box-shadow: -30px -10px 0 #FFFDEA;
                   
                }

                .navigation {
                    font-weight: 400;
                    font-size: 14px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 15px;

                    .nav-item {
                        display: flex;
                        gap: 5px;
                        align-items: center;
                    }

                    .yellow-dot {
                        min-width: 6px;
                        height: 6px;
                        border-radius: 3px;
                        background-color: #EDDD5E;
                    }

                    span {
                        font-weight: 700;
                        cursor: pointer;
                        color: white;
                    }
                }

                .call-search-container {
                    display: flex;
                    color: white;
                    font-weight: 700;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;

                    .call-text-container {
                        display: flex;
                        flex-direction: column;

                 
                    }

                    .search {
                        border-radius: 24px;
                        background: #FFF;
                        display: flex;
                        width: 20px;
                        height: 20px;
                        padding: 15px 16px 17px 16px;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;
                    }
                }
            }

            .contact-us-container {
                height: 80px;
                position: relative;
                padding: 5px 20px;
                background-color: #FFFDEA;
                align-items: center;
                justify-content: center;
                display: flex;
                border-radius: 0px 0px 0px 30px;
                cursor: pointer;

                .button-container {
                    display: flex;
                    width: 180px;
                    height: 60px;
                    justify-content: center;
                    gap: 10px;
                    align-items: center;
                    border-radius: 30px;
                    background: #EDDD5E;
                }
            }
        }
    }

   
}