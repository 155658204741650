.homepage-banner {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 80%;
    height: 350px;
    border-radius: 30px;

    @media (max-width: 767px) {
        width: 100%;
        height: 600px;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: inherit;

        .content {
            display: flex;
            align-items: center;
            justify-content: center;


            @media (max-width: 767px) {
                flex-direction: column;
                gap: 20px;
                padding: 0px 20px;
            }

            .icon-title-container {
                width: 60%;
                display: flex;
                gap: 20px;
                align-items: center;

                @media (max-width: 767px) {
                    width: 100%;
                    align-items: start;
                    flex-direction: column;
                }

                svg {
                    min-width: 80px;
                    height: 80px;
                    @media (max-width: 767px) {
                        width: 110px;
                        height: 110px;
                    }
                }

                span {
                    color: #FFF;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 55px;
                }
            }

            .discover-container {
                width: 30%;
                display: flex;
                justify-content: end;

                @media (max-width: 767px) {
                    width: 100%;
                    justify-content: start;
                }

                .cta-button {
                    // width: 100px;
                    height: 60px;
                    display: inline-flex;
                    align-items: center;
                    background-color: white;
                    color: black;
                    padding: 10px 25px;
                    border-radius: 30px;
                    font-size: 16px;
                    gap: 30px;
                    border: none;
                    cursor: pointer;



                    &:hover {
                        background-color: #f0f0f0;
                    }
                }
            }
        }
    }
}