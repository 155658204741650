.homepage-events {
    position: relative;
    display: inline-flex;
    height: 700px;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;





    @media (max-width: 767px) {
        height: auto;
        padding-right: 10px;
        padding-left: 10px;
    }

    .bg-image-container {
        position: absolute;
        bottom: 0px;
        right: 0px;
    }



    .item {
        position: relative;
        // padding: 20px;
        border-radius: 26px;
        display: flex;
        flex-direction: column;
        gap: 15px;



        .image-container {
            position: relative;
            box-sizing: border-box;

            img {
                width: 100%;
                height: 100%;
                border-radius: 26px;

                @media (max-width: 767px) {
                    width: 350px;
                }

            }

            .category {
                top: 20px;
                left: 20px;
                position: absolute;
                height: 30px;
                background-color: white;
                padding: 0px 20px;
                border-radius: 30px;
                display: flex;
                align-items: center;
                gap: 15px;

                span {
                    color: #404A3D;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;

                }
            }

            .arrow-container {
                bottom: 5px;
                right: 0px;
                position: absolute;
                display: flex;
                width: 50px;
                height: 50px;
                border-radius: 50px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                background-color: #EDDD5E;
            }
        }


        .title {
            color: #404A3D;
            font-size: 23px;
            font-style: normal;
            font-weight: 500;
            line-height: 38px;

            
            @media (max-width: 767px) {

                color: #404A3D;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 27.728px
            }
        }


        .description {
            width: 80%;
            height: 80px;
            color: rgba(64, 74, 61, 0.80);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25.6px;

           
        }
    }

}