.side-menu-sub-projects-container {
    box-sizing: border-box;
    border-radius: 30px;
    background: #fff;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;

    .title-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-right: 10px;

        .title {
            color: #404A3D;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            text-transform: capitalize;
        }
    }

    .content {
        padding: 30px 5px;
        padding-right: 30px;
        color: #666;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.6px; 
        display: flex;
        flex-direction: column;
        gap: 30px;

        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            .bolded {
                font-weight: 700;
            }


            &.row {
                flex-direction: row;
            }

            .text-container {
                display: flex;
                flex-direction: column;
            }

            svg {
                cursor: pointer;
            }
        }

       
    }
}