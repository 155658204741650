.homepage-research {
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 3rem 0px;
    justify-content: center;
    align-items: center;
    background-color: rgba(71, 140, 65, 1);
    flex-shrink: 0;

    @media (max-width: 767px) {
        padding-right: 10px;
        padding-left: 10px;
    }

    .bg-image-container {
        position: absolute;
        bottom: 0px;
        right: 0px;
    }

    .carousel-wrapper {
        width: 80%;
        @media (max-width: 767px) {
            width: 100%;
        }
    }



    .item {
        position: relative;
        padding: 20px;
        border-radius: 26px;
        background: #FFF;
        display: flex;
        flex-direction: column;
        gap: 15px;

        @media (max-width: 767px) {
            width: 330px;
            gap: 5px;
        }

        .image-container {
            box-sizing: border-box;

            img {
                width: 100%;
                height: 100%;
                border-radius: 26px;
            }
        }


        .category {
            color: #999;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        .title {
            color: #404A3D;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            text-transform: capitalize;
        }

        .separator {
            height: 1px;
            width: 100%;
            background-color: rgba(64, 74, 61, 0.10);
        }

        .description {
            width: 80%;
            height: 80px;
            color: rgba(64, 74, 61, 0.80);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25.6px;
            @media (max-width: 767px) {
                height: auto;
            }
        }

        .arrow-container {
            bottom: 0;
            right: 0;
            position: absolute;
            display: flex;
            width: 55px;
            height: 55px;
            border-radius: 50px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            background-color: #EDDD5E;
        }
    }

}