.gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    justify-items: center; 
    align-items: center;
    img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.6s ease-in-out;
        opacity: 0;
        animation: fadeIn 0.8s forwards;

        &:hover {
            transform: scale(1.2); 
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
        }
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
            transform: scale(0.95);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
}