.mobile-info-section-container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 100px 0px;
    border-radius: 30px;

    .image-container {
        width: 100%;
        height: 100%;
        position: absolute;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        z-index: 1;
        border-radius: inherit;
    }

    .section {
        padding: 0 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .title-help-text {
            width: 140px;
            height: 22px;
            padding: 2px 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #fff;
            border-radius: 50px;
            color: #FFF;
            font-size: 12px;
            color: #FFF;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .separator {
            height: 1px;
            background-color: #FFF;
        }

        .title {
            color: #FFF;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 48px;
        }

        .subtitle {
            width: 50%;
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;

        }

        .details-container {
            margin-top: 10px;
            width: 170px;
            height: 60px;
            background-color: #FFF;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;
        }
    }
}