.info-contact-card-container {
    // width: 100%;
    // height: 700px;
    height: 470px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;

    img {
        width: 100%;
        position: absolute;
        border-radius: inherit;
        z-index: 1;
    }

    .content {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
        z-index: 2;

        .title {
            color: #FFF;
            text-align: center;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 35px;
        }

        .phone {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            color: #FFF;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
        }

        .contact-button {
            width: 200px;
            height: 60px;
            padding-left: 20px;
            border-radius: 30px;
            background-color: #FFF;
            display: flex;
            gap: 30px;
            justify-content: center;
            align-items: center;
            color: #404A3D;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            text-transform: capitalize;
        }
    }
}