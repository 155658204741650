.laboratory-1-achievements {
    .achievements-container {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        justify-content: space-between;
        margin-top: 20px;
    }

    .achievement-section {
        flex: 1 1 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .achievement-image {
        width: 100%;
        max-width: 300px;
        height: auto;
        border-radius: 10px;
        margin-bottom: 15px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            margin-bottom: 15px;
            line-height: 1.6;
            border-bottom: 1px solid  #6663;
                white-space: pre-line;
        }
    }

   
}